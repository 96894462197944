<template>
  <v-container style="max-width: 800px; position: relative;">
    <Header/>
    <div v-if="this.$store.state.organization.orgId === '426834'">
      <ContactInfoRequired/>
    </div>
    <div v-else>
      <ContactInfo/>
    </div>
    <Footer/>
  </v-container>
</template>

<script>
import Header from '../components/Header.vue'
import ContactInfo from '../components/ContactInfo.vue'
import Footer from '../components/Footer.vue'
import ContactInfoRequired from "@/components/ContactInfoRequired.vue";

export default {
  components: {
    ContactInfoRequired,
    Header,
    ContactInfo,
    Footer
  },
  layout: 'report',
  mounted: function () {
    if (window.location.host == "vihjaa.fi") {
      window.location.replace("https://vihjaa.fi");
    }
  }
}
</script>

